@import "variable.scss";

h1 {
  font-family: "Libre Franklin", sans-serif !important;
  font-size  : 35px;
  font-weight: 800;
  line-height: normal;
}

h2 {
  font-family: "Libre Franklin", sans-serif !important;
  font-size  : 35px;
  font-weight: 600;
  line-height: normal;
}

h3 {
  font-family: "Libre Franklin", sans-serif !important;
  font-size  : 21px;
  font-weight: 500;
  line-height: normal;
}

p {
  font-family: "Libre Franklin", sans-serif !important;
  font-size  : 14px;
  font-weight: normal;
  line-height: normal;
}

label {
  font-family: "Libre Franklin", sans-serif !important;
  font-size  : 14px;
  font-weight: normal;
  line-height: normal;
}

.purple-button {
  font-family: "Libre Franklin", sans-serif !important;
  font-size  : 16px;
  font-weight: 600;
  border-radius: 8px;
}

a {
  font-family: "Libre Franklin", sans-serif !important;
  font-size  : 14px;
  font-weight: 600;
}

.purple-title {
  color: $title-color;
}

.purple-subtitle {
  color: $primary-color;
}

.purple-header-menu-button {
  font-family   : "Libre Franklin", sans-serif;
  font-weight: 700;
}


@media screen and (max-width: 769px) {
  h1 {
    font-family: "Libre Franklin", sans-serif !important;
    font-size  : 30px;
    font-weight: 600;
  }

  h2 {
    font-family: "Libre Franklin", sans-serif !important;
    font-size  : 23px;
    font-weight: 500;
  }

  h3 {
    font-family: "Libre Franklin", sans-serif !important;
    font-size  : 18px;
    font-weight: normal;
  }

  p {
    font-family: "Libre Franklin", sans-serif !important;
    font-size  : 14px;
    font-weight: normal;
  }

  label {
    font-family: "Libre Franklin", sans-serif !important;
    font-size  : 14px;
    font-weight: normal;
  }

  .purple-button {
    font-family: "Libre Franklin", sans-serif !important;
    font-size  : 16px;
    font-weight: 600;
  }

  a {
    font-family: "Libre Franklin", sans-serif !important;
    font-size  : 14px;
    font-weight: 600;
  }
}
